<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    PAYMENT HISTORY
                </div>
            </div>
            <div class="page_head-item">
                from
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaymentHistory",
        data() {
            return {
                borderPosition: true,
            }
        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
        },
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1700px;
            width: 100%;
            margin: 0 auto;
            transition: .4s;
            &.straight_length {
                max-width: 1600px;
                transition: .4s;
            }
        }
        &_head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 30px 5px;

            &-item {
                display: flex;
                align-items: center;
            }

            &-title {
                color: $main_blue;
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 0;
                text-transform: uppercase;
            }

        }
    }
</style>